<template>
  <div>
    <general-board
      v-if="isGeneralProject && !loader"
    />
    <project-board
      v-if="!isGeneralProject && project_dtl && !loader"
      :from="'generalBoard'"
      :board-project-id="projectId"
      :project-dtl="project_dtl"
    />

    <div v-if="loader">
      <div>
        <div class="shimmer assignee-shimmer" />
      </div>
      <BoardShimmer />
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import generalBoard from './Board.vue'
import projectBoard from '../project/Board/board.vue'
import BoardShimmer from '../shimmer/BoardShimmer.vue'

export default {
  name: 'Board',
  components: {
    generalBoard,
    projectBoard,
    BoardShimmer,
  },

  data() {
    return {
      projectId: null,
      project_dtl: null,
      loader: false,
    }
  },
  computed: {
    isGeneralProject() {
      if (!this.projectId) {
        return true
      }
      if (this.$store.state.app.productiveProjectList && !this.$store.state.app.productiveProjectList.length) {
        return true
      }

      // Find the project in the array that matches both projectId and projectName
      const project = this.$store.state.app.productiveProjectList.find(project => project.id === this.projectId && project.code == 'ROJ')

      // Check if project is not undefined (meaning projectId and projectName both match)
      if (project) {
        return true
      }
      return false
    },
  },
  destroyed() {
    this.$store.dispatch('app/CLEAR_BOARD_BACKGROUND_COLOR')
  },
  mounted() {
    this.getProjectsData('generalBoard')
    if (this.$route.query && this.$route.query.projectId) {
      if (this.$route.query.type === 'general') {
        this.projectId = null
      } else {
        this.projectId = this.$route.query.projectId
        this.getProjectDetail()
      }
    }
    eventBus.$on('newProjectId', data => {
      this.projectId = data
      this.project_dtl = null
      if (this.projectId) this.getProjectDetail()
    })
  },
  methods: {
    async getProjectDetail() {
      this.loader = true
      const { projectId } = this
      const taskId = (this.$route.query && this.$route.query.taskId) || null

      const response = await this.getHTTPPostResponse(
        `project/view/${projectId}`,
        {},
        false,
      )
      if (response && response.status == 200 && response.data) {
        this.project_dtl = response.data.project
        this.getProjectUser(this.project_dtl.id)

        /** Set project id & slug into current route parmas */
        let newQuery = {}
        if (taskId) {
          newQuery = {
            projectId, type: 'project', taskId,
          }
        } else {
          newQuery = { projectId, type: 'project' }
        }
        this.routeQueryUpdate(newQuery)
      }

      this.loader = false
    },
    async getProjectUser(id) {
      this.loader = true
      const response = await this.getHTTPPostResponse(
        'project/user/sort-project-user-list',
        {
          project_id: id,
        },
        false,
      )
      if (response && response.status === 200) {
        const { data } = response
        this.project_dtl.project_user = data.project_users
        this.$store.state.app.projectUsers = this.project_dtl.project_user
      }
      this.loader = false
    },
  },

}</script>
